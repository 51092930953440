.navbarBrandAlt {
  display: block;
}
.logo_topo2 {
  height: 52px;
  margin: auto;
  width: auto;
}
.padding {
  font-size: 15px;
  font-weight: 500;
  line-height: 28px;
  -webkit-transition: all 0.5s !important;
  transition: all 0.5s !important;
  display: inline-flex !important;
  align-items: center;
  margin-left: 5px;
  margin-right: 5px;
  height: 91px;
}

.cor_fundo_claro {
  height: 95px;
  border: 0;
  border-radius: 0;
  margin-bottom: 0;
  -webkit-transition: transform 0.2s;
  transition: transform 0.2s;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

.bgNoBlur {
  backdrop-filter: blur(0px) !important;
  -webkit-backdrop-filter: blur(0px) !important;
}
.cor_fundo_claro_bottom {
  z-index: 1036 !important;
  height: 75px;
  border: 0;
  margin-bottom: 0;
  -webkit-transition: transform 0.2s;
  transition: transform 0.2s;
  border-radius: 20px 20px 0 0;
}

.dropdown-toggle::after {
  display: none;
  margin-left: auto;
  vertical-align: auto;
  content: "";
  border-top: none;
  border-right: none;
  border-bottom: initial;
  border-left: none;
}
.navbarScrollCustom {
  transform: translateY(-95px);
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s;
}
.navbarScrollCustomBottom {
  transform: translateY(95px);
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s;
}
.logoIn {
  display: none;
}
.logoOut {
  display: none;
}

.mySidenaver {
  z-index: 9999;
  position: fixed;
  top: 45vh;
  margin-left: -140px;
  transition: 0.3s;
  padding: 13px;
  width: 250px;
  text-decoration: none;
  font-size: 20px;
  text-align: right;
  display: flex;
  background-position: right;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 110px;
}

.mySidenaver:hover {
  margin-left: 0;
}
.content {
  padding-right: 20px;
  font-size: 12px;
  letter-spacing: 0;
}
.mySidenaverContent {
  display: flex;
  align-items: center;
  height: 80px;
}
.iconCompramosWidget {
  width: 40px;
  margin-left: auto;
  border-bottom: 0;
  margin-right: 0;
}
/* .navbarBrand {
  position: absolute;
  left: 0;
  right: 0;
} */

.boxMenu {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.55px;
  line-height: 14px;
  cursor: pointer;
  z-index: 3;
  text-align: center;
}
a.padding {
  position: relative;
  padding: 6px 12px;
}

.boxMenu + span,
.boxMenu + span {
  border-radius: 10px;
  position: absolute;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  bottom: -2px;
  height: 5px;
  width: 46px;
  left: 0;
  right: 0;
  margin: auto;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 0% 100%;

  -webkit-transition: background-size 0.3s, color 0.3s;
  -moz-transition: background-size 0.3s, color 0.3s;
  transition: background-size 0.3s, color 0.3s;
}

.boxMenu.active + span,
.boxMenu:hover + span {
  background-size: 100% 100%;
}

.boxMenuItem {
  font-size: 13px;
  font-weight: 500 !important;
  letter-spacing: 0.65px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  line-height: 50px;
  display: flex !important;
  align-items: center;
}
.boxMenuItem.active,
.boxMenuItem:hover {
  text-decoration: none;
  background-color: transparent !important;
}
.boxMenuBottom + span,
.boxMenuBottom + span {
  bottom: 8px;
}
.boxMenuBottom svg {
  height: 27px;
  width: auto;
}

.opcoesEnter {
  opacity: 0;
  max-height: 0;
}
.opcoesEnterActive {
  opacity: 1;
  max-height: 100%;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.opcoesExit {
  opacity: 1;
  max-height: 100%;
}
.opcoesExitActive {
  opacity: 0;
  max-height: 0;

  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.hamburger {
  height: 27px;
  width: auto;
  margin-bottom: 0.5rem;
}

@media only screen and (max-width: 991px) {
  .navbarBrand {
    margin-right: 0;
  }
  .cor_fundo_claro_bottom {
    border-radius: 25px 25px 0 0;
  }
  .mySidenaver {
    margin-left: -95px;
    min-height: 120px;
    justify-content: center;
    background-size: contain;
    padding-left: 85px;
    top: 60vh;
  }
  .mySidenaver:hover {
    margin-left: -95px;
  }
  .mySidenaverContent {
    flex-direction: column-reverse;
    justify-content: center;
    text-align: center;
    height: 100px;
  }
  .content {
    padding-right: 0px;
  }
  .iconCompramosWidget {
    margin: auto;
    margin-top: -15px;
    width: 70px;
    height: auto;
    margin-bottom: 5px;
  }

  .padding {
    margin-left: 0px;
    margin-right: 0px;
    width: 20%;
    justify-content: center;
    position: relative;
  }
  .padding > button,
  .padding > a {
    padding: 0;
  }
  .cor_fundo_claro {
    height: 85px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .hamburger {
    height: 22px;
    width: 22px;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 575px) {
  .logo_topo2 {
    height: 22px;
  }
}
@media only screen and (max-width: 420px) {
  .boxMenu {
    font-size: 2.5vw;
  }
}
@media only screen and (max-width: 380px) {
  .cor_fundo_claro {
    height: 85px;
    padding-left: 0;
    padding-right: 0;
  }
}
